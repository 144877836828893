import { Unsub } from './types';

/** A function with an objective. to raise events */
export type Emitter<T> = (payload: T) => void;

/** A function with a need, to get notified */
export type Subscriber<T> = (payload: T) => void;

/**
 * Direct source to subscribers event construct\
 * Only the source, the one that created an instance of this, can emit an event
 */
export class Event<TEvent> {
  private initialized = false;
  private subscribers: Subscriber<TEvent>[] = [];

  /**
   * One time init\
   * Caller gets the only way to raise events
   */
  public init(): Emitter<TEvent> {
    if (this.initialized) throw new Error('Already initialized');

    this.initialized = true;

    return (event: TEvent) => {
      this.subscribers.forEach((s) => s(event));
    };
  }

  /**
   * Subscribe to receive the event
   * @param subscriber Callback function
   * @returns An unsubscribe function - call it when done or face a leak!
   */
  public subscribe(subscriber: Subscriber<TEvent>): Unsub {
    this.subscribers.push(subscriber);

    return () => {
      const index = this.subscribers.findIndex((s) => s === subscriber);

      if (index === -1) {
        console.warn('Event:unsubscribe - could not locate subscriber', {
          subscriber,
        });
        return;
      }

      this.subscribers.splice(index, 1);
    };
  }
}
